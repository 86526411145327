/* eslint-disable indent */
import { css } from "styled-components";
import { normalizeColor } from "grommet/utils";
import {
  Alert,
  CaretDownFill,
  CaretUpFill,
  CircleInformation,
  Info,
} from "grommet-icons";
import { FormNext, FormPrevious } from "grommet-icons";
import CloverleafLoadingIcon from "../components/atoms/LoadingIndicator/CloverleafLoadingIcon";
import { colors } from "./colors";

export const themeStyle = {
  name: "cloverleaf",
  rounding: 4,
  spacing: 18,
  defaultMode: "light",
  global: {
    breakpoints: {
      small: {
        value: 576,
        edgeSize: {
          none: "0px",
          hair: "1px",
          xxsmall: "4px",
          xsmall: "8px",
          small: "12px",
          medium: "16px",
          large: "24px",
          larger: "32px",
          largest: "42px",
          xlarge: "48px",
        },
      },
      medium: {
        value: 768,
        // Same as above, but needed for box.responsiveBreakpoint
        edgeSize: {
          none: "0px",
          hair: "1px",
          xxsmall: "4px",
          xsmall: "8px",
          small: "12px",
          medium: "16px",
          large: "24px",
          larger: "32px",
          largest: "42px",
          xlarge: "48px",
        },
      },
      large: {
        value: 960,
      },
      "desktop": {
        value: 1330,
      },
      // For my-assessments
      larger: {
        value: 1500,
      },
      xlarge: {
        value: 1649,
      },
      ultrawide: {
        value: 1650,
      },
    },
    colors,
    size: {
      hair: "18px",
      xxxsmall: "24px",
      xxsmall: "48px",
      xsmall: "96px",
      small: "192px",
      medium: "384px",
      large: "468px",
      xlarge: "576px",
      xxlarge: "768px",
      xxxlarge: "960px",
      full: "100%",
    },
    edgeSize: {
      none: "0px",
      hair: "1px",
      xxsmall: "4px",
      xsmall: "8px",
      small: "12px",
      medium: "16px",
      large: "24px",
      larger: "32px",
      largest: "42px",
      xlarge: "48px",
      responsiveBreakpoint: "small",
    },
    elevation: {
      light: {
        "-small": "0px -1px 4px rgba(100, 100, 100, 0.30)",
        "-medium": "0px -3px 8px rgba(100, 100, 100, 0.50)",
      },
      dark: {
        "-small": "0px -1px 4px rgba(255, 255, 255, 0.20)",
        "-medium": "0px -6px 8px rgba(255, 255, 255, 0.40)",
      },
    },
    focus: {
      shadow: {
        size: "1px",
      },
      border: {
        color: "green700",
      },
    },
    font: {
      family: "\"Roboto Condensed\", sans-serif",
      size: "1rem",
    },
    active: {
      background: "grey100",
      color: "active-text",
    },
    hover: {
      background: "background-back",
      color: "active-text",
    },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
    control: {
      border: {
        color: "grey500",
        radius: "4px",
      },
    },
    drop: {
      border: {
        radius: "4px",
      },
      // For Switch Account Menu
      // TODO: remove when z-index is normalized across the application
      zIndex: 99999,
      "max-height": "min-content",
    },
    input: {
      font: {
        weight: "normal",
      },
      padding: "10px",
    },
  },
  page: {
    wide: {
      alignSelf: "start",
      width: { min: "medium", max: "xxxlarge" }
    },
  },
  layer: {
    zIndex: 99999,
    container: {
      extend: props => {
        let css = {};

        if (props.top) { css.top = props.top }
        if (props.overflow) { css.overflow = props.overflow }

        if (props.screenSize === "small") {
          css.height = "100dvh !important";
          css.width = "100dvw !important";
        }

        return css;
      },
    },
    border: {
      radius: "8px", // xsmall
    },
  },
  /** custom property */
  nav: {
    global: {
      height: "44px",
      border: {
        color: "grey400",
        size: "hair",
        side: "bottom",
      },
    },
  },
  accordion: {
    heading: {
      margin: {
        vertical: "small",
        left: "medium",
        right: "none",
      },
    },
    panel: {
      border: {
        side: "top",
      },
    },
    icons: {
      color: "grey700",
    },
  },
  anchor: {
    color: "green600",
    fontWeight: "normal",
    extend: props => (
      props.uppercase && css`
        text-transform: uppercase;
      `
    ),
  },
  dataTable: {
    header: {
      color: "grey900",
    },
  },
  message: {
    kind: {
      ok: {
        background: "green600",
        foreground: "white",
      },
      info: {
        background: "purple50",
        foreground: "purple600",
      },
      warning: {
        background: "yellow50",
        foreground: "yellow900",
      },
      error: {
        background: "red700",
        foreground: "white",
      },
    },
  },
  notification: {
    global: {
      container: {
        align: "center",
        justify: "center",
      },
    },
    toast: {
      container: {
        round: "xsmall",
        width: "xlarge",
        elevation: "medium",
      },
      time: 2000,
    },
    title: {
      weight: "normal",
    },
    message: {
      as: "div",
      margin: { vertical: "xxsmall" },
      responsive: false,
      // Extend paragraph
      style: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    actions: {
      color: "white",
      size: "medium",
      decoration: "none",
    },
    textContainer: {
      direction: "column",
      justify: "center",
      fill: true,
    },
    critical: {
      color: {
        light: "red700",
        dark: "white",
      },
      icon: CircleInformation,
      toast: {
        background: "status-critical",
      },
      global: {
        background: "status-critical",
        opacity: "normal",
      }
    },
    warning: {
      color: {
        light: "yellow900",
        dark: "white",
      },
      icon: Alert,
      toast: {
        background: "status-warning",
      },
      global: {
        background: "status-warning",
        opacity: "normal",
      }
    },
    normal: {
      color: {
        light: "green800",
        dark: "white",
      },
      icon: Info,
      toast: {
        background: "status-ok",
      },
      global: {
        background: "status-ok",
        opacity: "normal",
      }
    },
    info: {
      color: {
        light: "purple700",
        dark: "white",
      },
      icon: CircleInformation,
      toast: {
        background: "status-info",
      },
      global: {
        background: "status-info",
        opacity: "normal",
      }
    },
  },
  box: {
    // when we switch rows to columns
    responsiveBreakpoint: "medium",
    extend: {
      // fix iE11 nested/flex height bug
      "-ms-flex-negative": 0,
    },
  },
  carousel: {
    animation: {
      duration: 0,
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
  button: {
    size: {
      medium: {
        border: {
          radius: "4px",
        },
      },
    },
    badge: {
      container: {
        background: "green500",
        extend: "flex: true",
      },
      size: {
        medium: "18px",
      },
    },
    border: {
      radius: "4px",
    },
    color: "white",
    padding: {
      vertical: "8px",
    },
    hover: {
      primary: {
        color: "white",
        background: {
          color: "green700",
        },
        padding: {
          horizontal: "16px",
          vertical: "8px",
        },
      },
      secondary: {
        background: "grey700",
        border: {
          color: "grey700",
          width: "1px",
        },
        color: "white",
        padding: {
          horizontal: "16px",
          vertical: "8px",
        },
        extend: css`
          [data-icon] { 
            color: ${props => props.theme.global.colors.white}
          }
        `,
      },
      coaching: {
        extend: css`
          [data-icon] { 
            color: ${props => props.theme.global.colors.white}
          }
        `,
      },
      option: {
        background: "background-back",
      },
      default: {
        background: {
          color: "background-back",
        },
        color: "grey700",
        extend: css`
          [data-icon] { 
            color: ${props => props.theme.global.colors.white}
          }
        `,
      },
    },
    primary: {
      background: {
        color: "green600",
      },
      padding: {
        horizontal: "16px",
        vertical: "8px",
      },
      border: "none",
    },
    secondary: {
      border: {
        color: "grey500",
        radius: "4px",
        width: "1px",
      },
      color: "grey700",
      padding: {
        horizontal: "16px",
        vertical: "8px",
      },
    },
    coaching: {
      border: {
        color: "grey500",
        radius: "4px",
        width: "1px",
      },
      color: "blue600",
      padding: {
        horizontal: "16px",
        vertical: "8px",
      },
    },
    option: {
      background: {
        color: "white",
      },
      border: {
        size: "0px",
        radius: "0px",
      },
      color: "grey700",
      padding: "small",
    },
    default: {
      background: {
        color: "white",
      },
      border: {
        color: "border",
        radius: "4px",
        width: "1px",
      },
      color: "grey700",
    },
    extend: props => {
      let result = "";

      if (props.plain && !props.disabled) {
        result += `
          &:hover,
          &:active,
          &:focus {
            color: ${getThemeColor("green600")};
          }`;
      }

      if (props.plain && props.hasIcon
        // Hack, see OrganizationSidebarListItem
        && props.kind !== "secondary"
        && !props.disabled
      ) {
        result += `
          &:hover [data-icon],
          &:active [data-icon],
          &:focus [data-icon] {
            color: ${getThemeColor("green600")};
          }`;
      }

      if (props.uppercase) result += "text-transform: uppercase;";
      if (props.capitalize) result += "text-transform: capitalize;";
      if (props.nowrap) result += "white-space: nowrap;";

      if (props.id?.endsWith("MoveUp") || props.id?.endsWith("MoveDown")) {
        result += "margin-left: 2px; padding: 2px 4px;";
      }

      return css`${result}`;
    },
  },
  checkBox: {
    // TODO: Try to apply to toggle but not generic checkboxes
    // border: {
    //   color: 'transparent',
    //   width: '0px',
    // },
    color: "green600",
    check: {
      radius: "4px",
      thickness: "3px",
    },
    hover: {
      border: {
        color: {
          dark: "green700",
          light: "green500",
        },
      },
    },
    /**
     * replace `extend` with label: { align: 'start' } }
     * after upgrading styled-components to v5
     */
    extend: `
      align-items: start;
    `,
    toggle: {
      background: "green200",
      untoggled: {
        background: "grey400",
        color: "grey700",
      },
      color: "green600",
      extend: css`
        background-color: ${props => (props.checked
          ? normalizeColor(props.theme.checkBox.toggle.background, props.theme)
          : normalizeColor(props.theme.checkBox.toggle.untoggled.background, props.theme)
        )};
      `,
      knob: {
        extend: css`
          background: ${props => (props.checked
            ? normalizeColor(props.theme.checkBox.toggle.color, props.theme)
            : normalizeColor(props.theme.checkBox.toggle.untoggled.color, props.theme)
          )};
        `,
      },
    },
  },
  icon: {
    size: {
      xsmall: "8px",
      small: "16px",
      medium: "24px",
      large: "48px",
      xlarge: "96px",
    },
  },
  radioButton: {
    border: {
      color: "grey600",
    },
    hover: {
      border: {
        color: "green500",
      },
    },
    check: {
      color: "green600",
      radius: "100%",
    },
    // Checked border color
    color: "green600",
    container: {
      extend: {
        alignItems: "start",
      },
    },
  },
  formField: {
    border: {
      color: "border",
      error: {
        color: {
          dark: "white",
          light: "status-critical",
        },
      },
      position: "inner",
      side: "all",
    },
    content: {
      pad: "small",
    },
    disabled: {
      background: {
        color: "status-disabled",
        opacity: "medium",
      },
    },
    error: {
      color: "status-critical",
      margin: {
        vertical: "xsmall",
        horizontal: "xsmall",
      },
      size: "small",
    },
    help: {
      color: "dark-3",
    },
    info: {
      color: "text-xweak",
      margin: {
        vertical: "xxsmall",
        horizontal: "none",
      },
    },
    label: {
      margin: {
        top: "0px",
        horizontal: "none",
        bottom: "4px",
      },
    },
    margin: {
      bottom: "16px",
    },
    round: "4px",
  },
  select: {
    clear: {
      container: {
        pad: "small", background: "grey200",
      },
      text: { color: "normal" },
    },
    icons: {
      up: <CaretUpFill role="img"aria-hidden="true" color="grey700" />,
      down: <CaretDownFill role="img" aria-hidden="true" color="grey700" />,
      margin: {
        horizontal: "xxsmall",
      },
    },
    options: {
      text: {
        size: "medium",
      },
    },
  },
  spinner: {
    container: {
      animation: undefined,
    },
    icon: CloverleafLoadingIcon,
    size: {
      small: "48px",
      medium: "72px",
      large: "96px",
      xlarge: "120px",
    },
  },
  "stack": {
    extend: css`
      & > div {
        display: flex;
      }
    `,
  },
  avatar: {
    size: {
      xsmall: "24px",
      small: "40px",
      xlarge: "90px",
      dashboard: "123px",
    },
    text: {
      fontWeight: "400",
      size: {
        dashboard: "72px"
      }
    },
    extend: css`
      color: white;
    `,
  },
  tab: {
    color: "grey700",
    active: {
      color: "green600",
    },
    hover: {
      color: "green600",
    },
    border: {
      side: "bottom",
      size: "medium",
      color: "transparent",
      active: {
        color: "green600",
      },
      hover: {
        color: "green600",
      },
    },
    pad: {
      horizontal: "16px",
    },
    margin: {
      top: "small",
      bottom: "none",
      horizontal: "none",
    },
  },
  tabs: {
    header: {
      border: {
        color: "grey400",
        side: "bottom",
      },
    },
    panel: {
      extend: "align-self: stretch",
    },
  },
  table: {
    extend: props => props.tableStyles,
    header: {
      extend: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    body: {
      extend: css`
        color: ${props => props.theme.global.colors.grey700};
        font-size: 16px;
      `,
    },
  },
  textInput: {
    suggestions: {
      background: "grey100",
    },
    extend: props =>
      props.icon &&
      (props.reverse
        ? `padding-right: ${props.theme.global.edgeSize.largest};`
        : `padding-left: ${props.theme.global.edgeSize.largest};`
      ),
  },
  grommet: {
    extend: {
      background: "none",
    },
  },
  heading: {
    level: {
      1: {
        font: {
          weight: "normal",
        },
        xxsmall: {
          size: "1rem", // 16px
          height: "22px",
        },
        xsmall: {
          size: "1.25rem", // 20px
          height: "24px",
        },
        small: {
          size: "1.375rem", // 22px
          height: "28px",
        },
        medium: {
          size: "1.625rem", // 26px
          height: "30px",
        },
        large: {
          size: "1.875rem", // 32px
          height: "38px",
        },
        xlarge: {
          size: "2.125rem", // 38px
          height: "44px",
        },
      },
      2: {
        font: {
          weight: "normal",
        },
        xxsmall: {
          size: "1rem", // 16px
          height: "22px",
        },
        xsmall: {
          size: "1.25rem", // 20px
          height: "24px",
        },
        small: {
          size: "1.375rem", // 22px
          height: "28px",
        },
        medium: {
          size: "1.625rem", // 26px
          height: "30px",
        },
        large: {
          size: "1.875rem", // 32px
          height: "38px",
        },
        xlarge: {
          size: "2.125rem", // 38px
          height: "44px",
        },
      },
      3: {
        font: {
          weight: "normal",
        },
        xxsmall: {
          size: "1rem", // 16px
          height: "22px",
        },
        xsmall: {
          size: "1.25rem", // 20px
          height: "24px",
        },
        small: {
          size: "1.375rem", // 22px
          height: "28px",
        },
        medium: {
          size: "1.625rem", // 26px
          height: "30px",
        },
        large: {
          size: "1.875rem", // 32px
          height: "38px",
        },
        xlarge: {
          size: "2.125rem", // 38px
          height: "44px",
        },
      },
      4: {
        font: {
          weight: "normal",
        },
        xxsmall: {
          size: "1rem", // 16px
          height: "22px",
        },
        xsmall: {
          size: "1.25rem", // 20px
          height: "24px",
        },
        small: {
          size: "1.375rem", // 22px
          height: "28px",
        },
        medium: {
          size: "1.625rem", // 26px
          height: "30px",
        },
        large: {
          size: "1.875rem", // 32px
          height: "38px",
        },
        xlarge: {
          size: "2.125rem", // 38px
          height: "44px",
        },
      },
    },
    extend: props => (
      props.uppercase && css`
        text-transform: uppercase;
      `
    ),
  },
  text: {
    font: {
      family: "Roboto Condensed, sans-serif",
    },
    xsmall: {
      size: ".75rem", // 12px
      height: "14px",
    },
    small: {
      size: ".875rem", // 14px
      height: "16px",
    },
    medium: {
      size: "1rem", // 16px
      height: "22px",
    },
    large: {
      size: "1.25rem", // 20px
      height: "24px",
    },
    xlarge: {
      size: "1.5rem", // 24px
      height: "28px",
    },
    skeleton: {
      height: "22px",
    },
    extend: props => {
      let result = "";

      if (props.uppercase) result += "text-transform: uppercase;";
      if (props.capitalize) result += "text-transform: capitalize;";
      if (props.nowrap) result += "white-space: nowrap;";

      return result;
    },
  },
  tip: {
    content: {
      background: "grey300",
      pad: {
        horizontal: "xsmall",
        vertical: "xxsmall",
      },
      round: "xsmall",
    },
    drop: {
      margin: {
        top: "xxsmall",
      },
    },
  },
  paragraph: {
    font: {
      family: "Roboto",
    },
    small: {
      size: ".875rem", // 14px
      height: "16px",
      maxWidth: "336px",
    },
    medium: {
      size: "1rem", // 16px
      height: "22px",
      maxWidth: "432px",
    },
  },
};

export const getThemeColor = (color, theme = themeStyle) => {
  const darkMode = !!theme.dark;

  return normalizeColor(color, theme, darkMode);
};

export const iconButtonTheme = {
  button: {
    // "Trash" button
    secondary: {
      background: undefined,
      border: undefined,
      color: "grey700",
      padding: "8px",
    },
    hover: {
      secondary: {
        background: undefined,
        border: undefined,
        color: "red800",
        extend: `outline: 1px solid ${getThemeColor("red800")}`,
        padding: "8px",
      },
    },
  },
};

export const toggleButtonTheme = {
  radioButton: {
    extend: css`flex-grow: 1`,
    container: {
      extend: css`width: auto`,
    },
  },
  button: {
    primary: {
      background: "green200",
      color: "green700",
      border: {
          color: "green200",
          width: "1px",
      },
    },
    active: {
      primary: {
        background: "green600"
      },
    },
    focus: {
      border: {
        color: "focus",
      },
    },
    hover: {
      extend: css`&:active { background: "green700" }`
    },
  },
};

export const navbarButtonTheme = {
  button: {
    // Menu items as children
    // https://github.com/grommet/grommet/blob/master/src/js/components/Menu/Menu.js#L307
    option: undefined,
    border: "none",
    size: {
      medium: {
        pad: {
          horizontal: "10px",
          vertical: "8px",
        },
      },
    },
    primary: {
      background: {
        color: undefined,
      },
      padding: undefined,
    },
    secondary: {
      border: "none",
      padding: undefined,
    },
    active: {
      primary: {
        background: {
          color: "green700",
        },
        padding: undefined,
      },
      secondary: {
        border: "none",
        color: "text",
        padding: undefined,
      }
    },
    hover: {
      primary: {
        background: {
          color: "green700",
        },
        padding: undefined,
      },
      secondary: {
        background: {
          color: "background-back",
        },
        border: "none",
        color: "text",
        padding: undefined,
      },
    },
    extend: props => {
      let result = "";

      if (props.kind === "secondary") {
        result += `
          &:hover [data-icon],
          &:active [data-icon],
          &:focus [data-icon] {
            color: ${getThemeColor("green700")};
          }
        `;
      }

      return css`${result}`;
    },
  },
  menu: {
    group: {
      container: {
        pad: {
          vertical: "xxsmall",
        },
      },
      separator: {
        pad: {
          horizontal: "none",
          vertical: "none",
        }
      }
    }
  },
  global: {
    drop: {
      extend: () => {
        return css`
          min-width: 250px;
          width: "min-content";
          transition: width 0.5s ease;
        `
      }
    }
  },
};

export const PAGINATION_THEME = {
  pagination: {
    button: {
      color: "grey700",
      active: {
        background: {
          color: "grey300",
        },
      },
      hover: {
        background: {
          color: "active-background",
        },
        color: "white",
        extend: css`
          svg {
            stroke: ${props => props.theme.global.colors.white}
          }
        `,
      },
    },
    icons: {
      next: FormNext,
      previous: FormPrevious,
    },
  },
};
