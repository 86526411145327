import {
  useContext,
  useEffect,
  useState
} from "react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Nav,
  ResponsiveContext,
  Sidebar as GrommetSidebar,
  ThemeContext,
  Text
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { navbarButtonTheme } from "@/cloverleaf-ui/theme";
import { isMobile } from "@/cloverleaf-ui/utils";
import { AddUsersToOrgTeamModal } from "../../AddUsersToOrgTeamModal";
import { INVITE_TEAMMATES_MODAL_HASH } from "../../AddUsersToOrgTeamModal/utils";
import { BetaTag } from "../../BetaTag";
import {
  COLLAPSED_WIDTH,
  EXPANDED_WIDTH,
  FADED_WIDTH_TRANSITION,
  HEADER_HEIGHT
} from "../constants";
import { SidebarContext } from "./Sidebar.context";
import { SidebarButton } from "./SidebarButton";
import { SidebarFooter } from "./SidebarFooter";
import { Configuration, EVENT } from "@/components/utils/constants";
import { Button } from "@/components/v2/Button";
import { Log } from "@/lib/analytics/Log";
import {
  hasDirectoryEnabled,
  useLocale,
  useToastMessage
} from "@/lib/hooks";
import { useIsCloverleafEmployee } from "@/lib/hooks/useIsCloverleafEmployee";

const GET_SIDEBAR_DATA_QUERY = gql`
  query getSidebarData {
    currentUser {
      id
      isLead
      isCandidate
      # Pre release
      email
      trustingOrgs {
        organization {
          id
        }
      }
      organization {
        id
        isCoach
        callerCanManage
        configuration {
          id
          configurationId
          enabled
        }
        topParentOrganization {
          id
          isCurrentUserAdmin
        }
        directoryConfig: configurationSetting(configurationId: "${Configuration.DIRECTORY}") {
          enabled
        }
      }
      hasPromotionLinks: hasPaywallAccess(configurationId: "${Configuration.PROMO_LINKS}")
    }
    check {
      canInviteMembers # to default organization
      hasOrganizationReports
    }
  }
`;

const isActive = ({ currentPathname = "", pathname = "" }) => currentPathname.toLowerCase().startsWith(pathname.toLowerCase());

const Sidebar = (props) => {
  const { t } = useLocale();
  const router = useRouter();
  const { pushToast } = useToastMessage();
  const isInternalEmployee = useIsCloverleafEmployee();

  const isDefaultLocale = router.locale === router.defaultLocale || isInternalEmployee;

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const onHashChanged = () => {
      const url = window.location.hash;

      const urlHash = url?.split("#")[1];

      if (urlHash === INVITE_TEAMMATES_MODAL_HASH) {
        setIsModalOpen(true);
      }
    };

    window.addEventListener("hashchange", onHashChanged);

    return () => {
      window.removeEventListener("hashchange", onHashChanged);
    };
  }, []);

  const {
    hover,
    isSidebarOpen,
    setHover,
  } = useContext(SidebarContext);

  const screenSize = useContext(ResponsiveContext);

  const isOrganizationSettings = router.pathname.includes("/organization/[organizationId]/settings");

  const mobile = isMobile(screenSize);
  const isMobileOrganizationSettings = isOrganizationSettings && mobile;
  const isSidebarAnchored = isSidebarOpen && (!isOrganizationSettings || isMobileOrganizationSettings);
  const expanded = isSidebarAnchored || hover;
  const collapsedWidth = mobile ? "0px" : COLLAPSED_WIDTH;
  const width = isSidebarOpen && !isOrganizationSettings ? EXPANDED_WIDTH : collapsedWidth;

  const { loading, data } = useQuery(GET_SIDEBAR_DATA_QUERY);

  const currentUser = data?.currentUser || {};
  const showDirectory = hasDirectoryEnabled(currentUser);

  const isCandidate = currentUser.isCandidate;
  const currentUserOrganization = currentUser.organization;
  const organizationId = currentUserOrganization?.id;
  const canInviteMembers = organizationId && data?.check?.canInviteMembers;
  const hasOrganizationReports = organizationId && data?.check?.hasOrganizationReports &&
    // For now only showing to top parent org admins
    currentUserOrganization?.topParentOrganization?.isCurrentUserAdmin;

  const isManagePartnerOrganizationsEnabled = currentUserOrganization?.configuration?.some(
    config => (
      config.enabled
      && config.configurationId === Configuration.MANAGE_PARTNER_ORGANIZATIONS
    )
  );

  const isManagePartnerOrganizationsFullEnabled = currentUserOrganization?.configuration?.some(
    config => (
      config.enabled
      && config.configurationId === Configuration.MANAGE_PARTNER_ORGANIZATIONS_FULL
    )
  );

  const showMyOrganizationsButton =
    (
      // User is an admin of their org, and MANAGER_PARTNER_ORGANIZATIONS or MANAGER_PARTNER_ORGANIZATIONS_FULL is enabled
      currentUserOrganization?.callerCanManage
      && isManagePartnerOrganizationsEnabled
    )
    || (
      isManagePartnerOrganizationsFullEnabled
      && currentUser.trustingOrgs?.length > 0 // User has at least one trusting org
    );

  const showMyTeamsButton = isDefaultLocale && !currentUser.isLead && !isCandidate;
  const showDashboardBuilder = isDefaultLocale && organizationId && !currentUser.isLead && !isCandidate;
  const showMyNetworkButton = !currentUser.isLead && !isCandidate;

  const showLeadsButton = currentUser.hasPromotionLinks
    // For now only showing to top parent org admins
    && currentUserOrganization?.topParentOrganization?.isCurrentUserAdmin;

  const handleOnCloseInviteMembersModal = () => {
    const query = router.query;

    delete query.channel;

    router.replace({
      pathname: router.pathname,
      hash: undefined,
      query,
    });

    /**
     * When the modal closes, focus is returned to the last active element.
     * Tracking a false and null state can avoid triggering the sidebar to re-open.
     * When actively closing the modal, we will set the state to null instead of false.
     */
    return setIsModalOpen(null);
  };

  return (
    <Log section="sidebar">
      <GrommetSidebar
        background="transparent"
        gridArea="sidebar"
        pad="none"
        width={mobile ? collapsedWidth : width}
        responsive={false}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          transition: FADED_WIDTH_TRANSITION,
          zIndex: 1,
        }}
        {...props}
      >
        <Box
          background="white"
          justify="between"
          pad="none"
          elevation="medium"
          height={`calc(100% - ${HEADER_HEIGHT})`}
          width={expanded ? EXPANDED_WIDTH : collapsedWidth}
          style={{
            overflow: "hidden",
            position: "absolute",
            transition: FADED_WIDTH_TRANSITION,
          }}
        >
          <ThemeContext.Extend value={navbarButtonTheme}>
            <Nav
              aria-label="main navigation"
              gap="medium"
              responsive={false}
              margin={{ horizontal: "small", vertical: "medium" }}
            >
              <SidebarButton
                active={isActive({ currentPathname: router.pathname, pathname: "/my-dashboard" })}
                onFocus={() => setHover(true)}
                onBlur={() => setHover(false)}
                href="/my-dashboard"
                icon="house-user"
                label={t("my-dashboard")}
              />
              <SidebarButton
                active={isActive({ currentPathname: router.pathname, pathname: "/my-assessments" })}
                onFocus={() => setHover(true)}
                onBlur={() => setHover(false)}
                href="/my-assessments"
                icon="list"
                label={t("my-assessments")}
              />
              {showDashboardBuilder && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/reporting-teams" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href="/reporting-teams"
                  icon="fa-sitemap"
                  label={
                    <Box direction="row" gap="xsmall">
                      <Text>{t("reporting-teams")}</Text>
                      <BetaTag label="beta"/>
                    </Box>
                  }
                />
              )}
              {!loading && showMyTeamsButton && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/teams" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href="/teams"
                  icon="users" label={t("my-teams-title")}
                />
              )}
              {showDashboardBuilder && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/coaching-view" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href="/coaching-view"
                  icon="grid-horizontal"
                  label={t("dashboard-builder")}
                />
              )}
              {!loading && showMyNetworkButton && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/my-network" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href="/my-network"
                  icon={["far", "chart-network"]}
                  label={t("network.title")}
                />
              )}
              {showDirectory && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/organization/[organizationId]/directory" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href={{
                    pathname: "/organization/[organizationId]/directory",
                    query: { organizationId },
                  }}
                  icon="address-book"
                  label={t("organization-directory")}
                />
              )}
              {showMyOrganizationsButton && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/organization/[organizationId]/my-organizations" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href={{
                    pathname: "/organization/[organizationId]/my-organizations",
                    query: { organizationId },
                  }}
                  icon="city"
                  label={t("my-organizations")}
                />
              )}
              {hasOrganizationReports && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/organization/[organizationId]/reports" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href={{
                    pathname: "/organization/[organizationId]/reports",
                    query: { organizationId },
                  }}
                  icon="pie-chart"
                  label={t("org-reports")}
                  logEventProps={{
                    ui_properties: {
                      buttonText: t("org-reports"),
                    },
                  }}
                />
              )}
              {showLeadsButton && (
                <SidebarButton
                  active={isActive({ currentPathname: router.pathname, pathname: "/organization/[organizationId]/links" })}
                  onFocus={() => setHover(true)}
                  onBlur={() => setHover(false)}
                  href={{
                    pathname: "/organization/[organizationId]/links",
                    query: { organizationId },
                  }}
                  icon="seedling"
                  label={t("leads")}
                />
              )}
            </Nav>
          </ThemeContext.Extend>
          <SidebarFooter
            isOrganizationSettings={isOrganizationSettings}
            onFocus={() => {
              /**
               * When the modal closes, focus is returned to the last active element.
               * This will re-open the sidebar when focus is returned to this button.
               * Tracking a false and null state can avoid triggering the sidebar to re-open.
               */
              if (isModalOpen === null) {
                return setIsModalOpen(false);
              }

              return setHover(true);
            }}
            onBlur={() => setHover(false)}
            skeleton={loading}
          >
            {canInviteMembers && (
              <Button
                gap="medium"
                fill="horizontal"
                icon={(
                  <Box flex={false}>
                    <Icon
                      fixedWidth
                      icon={["far", "user-plus"]}
                      color="text-sidebar"
                    />
                  </Box>
                )}
                justify="start"
                label={t("invite-teammates")}
                logEventProps={{
                  elementName: "open-invite-teammates-modal-button",
                }}
                onClick={() => setIsModalOpen(true)}
                secondary
                nowrap
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  transition: FADED_WIDTH_TRANSITION,
                  // Adjust icon alignment to match sidebar theme icons
                  padding: "8px 10px",
                }}
                uppercase
              />
            )}
          </SidebarFooter>
        </Box>
      </GrommetSidebar>
      {isModalOpen && (
        <AddUsersToOrgTeamModal
          inviteChannel={EVENT.InviteSent.props.channel.sidebar}
          onClose={() => handleOnCloseInviteMembersModal()}
          onFailure={() => pushToast(t("send-invitation-failure"), "critical")}
          onSuccess={() => {
            handleOnCloseInviteMembersModal();

            return pushToast(t("send-invitation-success"))
          }}
          onCancel={handleOnCloseInviteMembersModal}
          organizationId={organizationId}
        />
      )}
    </Log>
  );
};

export { Sidebar }
