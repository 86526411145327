import React from "react";
import { gql, useQuery } from "@apollo/client";
import throttle from "lodash/fp/throttle";
import { omitUndefined } from "@/components/utils";

const SORT_COLUMNS = {
  ID: "ID",
  FIRST: "FIRST",
  LAST: "LAST",
  EMAIL: "EMAIL",
};

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

export const RELAY_USER_SEARCH_QUERY = gql`
  query relayUserSearch(
    $userId: ID
    $search: UserSearchInput
    $sort: [UserSort!]
    $first: Int
    $after: String
    $last: Int
    $before: String
    $organizationId: ID
  ) {
    relayUserSearch(
      userId: $userId, 
      search: $search,
      sort: $sort,
      first: $first,
      after: $after,
      last: $last,
      before: $before,
    ) {
      type
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          ... on UserNode {
            user {
              id
              fullName
              email
              avatar
              role
              isOnTeamWith
              canBeViewedByCurrentUser
              profile {
                id
                relativePublicUrl
              }
              created
              consentedAt
              isMember(organizationId:$organizationId)
              isMemberInHierarchy(organizationId:$organizationId)
              isAdmin(organizationId:$organizationId)
              isCandidate
            }
          }
        }
      }
    }
  }
`;

export const CORSS_ORG_RELAY_USER_SEARCH_QUERY = gql`
  query crossOrgRelayUserSearch(
    $userId: ID
    $search: UserSearchInput
    $sort: [UserSort!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    crossOrgRelayUserSearch(
      userId: $userId,
      search: $search,
      sort: $sort,
      first: $first,
      after: $after,
      last: $last,
      before: $before,
    ) {
      type
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          ... on UserNode {
            user {
              id
              fullName
              email
              avatar
              role
              isOnTeamWith
              canBeViewedByCurrentUser
              profile {
                id
                relativePublicUrl
              }
              created
              consentedAt
              isCandidate
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @param {object} input
 * @param {string} input.userId - ID!
 * @param {object} input.search - search query parameters
 * @param {string} input.search.text - search by name or email
 * @param {string} input.search.topParentId - ID!
 * @param {object} input.search.userSearchFilters - additional search filters
 * @param {array} input.search.userSearchFilters.userStatus - return users in the included statuses: ACTIVE, INACTIVE, DELETED
 * @param {array} input.search.userSearchFilters.managedUsers - only return users that the caller is an admin of an org/group the user is a member of
 * @param {boolean} input.search.userSearchFilters.includeCandidates
 * @param {boolean} input.search.userSearchFilters.includeLeads
 * @param {boolean} input.search.userSearchFilters.includeCallingUser
 * @param {boolean} input.search.userSearchFilters.includeOrgAdmins
 * @param {boolean} input.search.userSearchFilters.onlyCandidates
 * @param {boolean} input.search.userSearchFilters.onlyLeads
 * @param {boolean} [input.search.userSearchFilters.onlyCanView = true] - enforce profile visibility
 * @param {boolean} input.search.userSearchFilters.onlySharedGroupMembers - filters to members (only direct members) of groups the caller is in
 * @param {array} input.search.userSearchFilters.membersOfOrganizationIds - filters to members (only direct members) of provided group ids
 * @param {boolean} input.search.userSearchFilters.onlySharedTeamMembers - filters to members (only members) of teams the caller is in
 * @param {array} input.search.userSearchFilters.membersOfTeamIds - filters to members (only members) of provided team ids
 * @param {boolean} input.search.userSearchFilters.onlyCoachingNetwork - filters to only members of coach
 * @param {boolean} input.search.notOnlyInTopParent - Toggle off default filtering to only top parent organization (which may be useful for Coaching Network)
 * @param {object} input.sort - sort query parameters
 * @param {string} input.sort.column - Column to order by: ID, FIRST, LAST, EMAIL
 * @param {string} input.sort.order - Order by: ASC, DESC
 * @param {number} input.first - Limit first pagination
 * @param {string} input.after
 * @param {number} input.last
 * @param {string} input.before
 * @param {boolean} input.crossOrgSearch
 */
const useRelayUserSearch = ({
  userId,
  search: _search,
  sort: _sort,
  first,
  after,
  last,
  before,
  organizationId,
  crossOrgSearch = false,
  ...rest
}) => {
  const [search, setSearch] = React.useState({
    ..._search,
    userSearchFilters: {
      onlyCanView: true, // set to true by default
      ..._search?.userSearchFilters || {},
    },
  });

  const initialSort = [{
    column: _sort?.column || SORT_COLUMNS.ID,
    order: _sort?.order || SORT_ORDER.ASC,
  }];

  const [sort, setSort] = React.useState(initialSort);

  const variables = omitUndefined({
    userId,
    search,
    sort,
    first,
    after,
    last,
    before,
    organizationId,
  });

  const {
    data,
    error,
    loading,
    fetchMore,
    refetch,
  } = useQuery(crossOrgSearch ? CORSS_ORG_RELAY_USER_SEARCH_QUERY : RELAY_USER_SEARCH_QUERY, {
    variables,
    ...rest
  });

  const searchData = crossOrgSearch ? data?.crossOrgRelayUserSearch : data?.relayUserSearch;
  const users = searchData?.edges?.map(edge => edge?.node?.user);

  const pageInfo = searchData?.pageInfo || {};

  const debouncedRefetch = React.useCallback((v) => {
    return throttle({ wait: 1000, leading: true })(() => refetch(v));
  }, [refetch]);

  /**
   * @param {object} variables
   */
  const handleFetchMore = (args = {}) => {
    if (pageInfo?.hasNextPage) {
      return fetchMore({
        variables: {
          ...variables,
          ...args,
          after: pageInfo?.endCursor,
        },
      });
    }
  };

  /**
   * @param {SORT_COLUMNS} column
   * @param {SORT_ORDER} order
   */
  const handleSort = (
    column = SORT_COLUMNS.ID,
    order = SORT_ORDER.ASC,
  ) => {
    const updatedSort = [{
      column,
      order,
    }];

    setSort(updatedSort);

    return refetch({
      ...variables,
      sort: updatedSort
    });
  };

  const handleSearch = async (query) => {
    const { text, topParentId, userSearchFilters } = query;

    const variables = { ...search };

    if (typeof text !== "undefined") {
      variables.text = text;
    }

    if (typeof topParentId !== "undefined") {
      variables.topParentId = topParentId;
    }

    if (userSearchFilters) {
      variables.userSearchFilters = {
        ...variables.userSearchFilters,
        ...userSearchFilters,
      };
    }

    setSearch(variables);

    return debouncedRefetch({
      ...variables,
    });
  }

  return {
    data,
    error,
    fetchMore: handleFetchMore,
    loading,
    refetch: debouncedRefetch,
    search: handleSearch,
    sort: handleSort,
    users,
  };
}

export { useRelayUserSearch };
